// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learning-and-development-js": () => import("./../../../src/pages/learning-and-development.js" /* webpackChunkName: "component---src-pages-learning-and-development-js" */),
  "component---src-pages-open-source-js": () => import("./../../../src/pages/open-source.js" /* webpackChunkName: "component---src-pages-open-source-js" */),
  "component---src-pages-personal-projects-js": () => import("./../../../src/pages/personal-projects.js" /* webpackChunkName: "component---src-pages-personal-projects-js" */),
  "component---src-pages-professional-projects-js": () => import("./../../../src/pages/professional-projects.js" /* webpackChunkName: "component---src-pages-professional-projects-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-pages-skills-js": () => import("./../../../src/pages/skills.js" /* webpackChunkName: "component---src-pages-skills-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-personal-project-modal-js": () => import("./../../../src/templates/personal-project-modal.js" /* webpackChunkName: "component---src-templates-personal-project-modal-js" */)
}

