module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Guillaume Bouffard official website","short_name":"Guillaume Bouffard website","start_url":"/","background_color":"#FFF","theme_color":"#FFF","display":"minimal-ui","icon":"src/images/gbouffard.png","icons":[{"src":"/gbouffard-48.png","sizes":"48x48","type":"image/png"},{"src":"/gbouffard-72.png","sizes":"72x72","type":"image/png"},{"src":"/gbouffard-96.png","sizes":"96x96","type":"image/png"},{"src":"/gbouffard-128.png","sizes":"128x128","type":"image/png"},{"src":"/gbouffard-144.png","sizes":"144x144","type":"image/png"},{"src":"/gbouffard-192.png","sizes":"192x192","type":"image/png"},{"src":"/gbouffard-256.png","sizes":"256x256","type":"image/png"},{"src":"/gbouffard-384.png","sizes":"384x384","type":"image/png"},{"src":"/gbouffard-512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"faf06e24a48a779cd89044b497ff241d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"overlay":{"position":"fixed","top":0,"left":0,"right":0,"bottom":0,"backgroundColor":"rgba(255, 255, 255, 0.75)"},"style":{"content":{"position":"absolute","top":"40px","left":"40px","right":"40px","bottom":"40px","border":"1px solid #ccc","background":"#fffafa","overflow":"auto","WebkitOverflowScrolling":"touch","borderRadius":"4px","outline":"none","padding":"16px 40px 20px"}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
